@import url('https://fonts.cdnfonts.com/css/foco');

* {
  font-family: 'Foco', sans-serif !important;
}

:root {
  --cor-cinza-suave: #c4c4c4;
}

