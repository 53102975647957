nav.navbar {
    /* flex-wrap: nowrap !important; */
    background: linear-gradient(to right, var(--cor-primaria), var(--cor-primaria-2));
}

nav .nav-search input {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    position: relative;
    width: 100%;
    height: 2.25rem;
    padding: 0.75rem 1rem;
    color: #fff;
    border-radius: 0.25rem;
    z-index: 1;
}

nav button svg {
    font-size: 26px;
    color: #fff
}

nav button,
nav button:active,
nav button:hover {
    border: 0 !important;
    background-color: transparent !important;
}

.navbar button svg:hover {
    color: #fff;
}

.navbar .nav-search svg {
    color: var(--cor-cinza);
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
}

nav .qtd-cesta {
    position: relative;
    top: 12px !important;
    font-size: 10px;
    right: -5px;
    background-color: var(--cor-secundaria);;
}

.offcanvas.offcanvas-start {
    top: 52px;
    left: 0;
    width: 100%;
    /* border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color); */
    background: linear-gradient(to right, var(--cor-primaria), var(--cor-primaria-2));
    transform: translateX(-100%);
}

.offcanvas .offcanvas-header h2 {
    color: #fff;
    font-weight: bold;
}

.offcanvas .offcanvas-body ul.opcoes {
    list-style: none;
    padding: 0;
}

.offcanvas .offcanvas-body ul.opcoes li {
    color: #fff;
    padding: 13px 20px 20px 13px;
    border-radius: 5px;
    font-size: 0.875rem;
    line-height: 0.875rem;
    font-weight: 400;
}

.offcanvas .offcanvas-body ul.opcoes li:hover {
    color: #fff;
    cursor: pointer;
    transition: all .5s;
    background-color: var(--cor-primaria-2);
}

header a {
    color: #fff;
    text-decoration: none;
}