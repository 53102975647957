/* Paleta de cores personalizada */
:root {
    --primary-color: #255176;
    --secondary-color: #2c3e50;
}

img {
    width: 100px;
}

.header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--secondary-color);
}

.header p {
    font-size: 1.25rem;
    color: var(--secondary-color);
}

/* Estilização das seções */
section h2 {
    color: var(--primary-color) !important;
    font-size: 2rem;
    font-weight: bold;
}

.client-card {
    background-color: #f8f9fa;
    border-radius: 10px;
}

.cta-section {
    background-color: var(--primary-color) !important;
}

.cta-section h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #f8f9fa !important;
}

.cta-section a.btn {
    background-color: #fff;
    color: var(--primary-color) !important;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 1.2rem;
}

/* Responsividade */
@media (max-width: 768px) {
    .header h1 {
        font-size: 2rem;
    }

    .header p {
        font-size: 1rem;
    }
}
