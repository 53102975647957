:root {
    --cor-primaria: #006CBC;
    --cor-primaria-2: #005c9e;

    --cor-secundaria: #f8a800;
    --cor-secundaria-2: #d49200;
    
    --cor-terciaria: #7A1312;
        
    --cor-verde: #38BAA6;

    --cor-vermelho: #f82500;

    --cor-fundo: #F2F2F2;
    
    --cor-preto: #181818;
}
