.owl-carousel .owl-stage {
    margin-top: 10px;
    margin-bottom: 10px;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    width: 40px;
    height: 40px;
    top: 45%;
    color: var(--cor-primaria);
    border-radius: 50%;
    font-size: 27px;
}
/*
.owl-carousel .owl-stage {
    position: relative;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    padding-left: 10px;
    padding-right: 10px;
} */

.owl-carousel.owl-drag .owl-item:first-child{
    padding-left: 10px;
    margin-left: 0;
}
.owl-carousel.owl-drag .owl-item:last-child{
    padding-right: 10px;
    margin-right: 0;
}

.owl-carousel .owl-nav button {
    /* background-color: #f3f3f3 !important; */
}

.owl-carousel .owl-nav .owl-prev {
    left: -10px;
    position: absolute;
}

.owl-carousel .owl-nav .owl-next {
    right: -10px;
    position: absolute;
}

.owl-dots {
    display: none;
}
