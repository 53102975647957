.card-produto {
    cursor: pointer;
    padding: 0px 10px 0px 10px;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
    border: 0.1px solid #f0f0f0;
    border-radius: 4px;
    overflow: hidden;
    border-radius: 8px;
    min-height: 280px !important;
    max-height: 280px !important;
}

.card-produto .btn {
    /* position: absolute;
    bottom: 10px; */
    font-size: 14px;
    width: 100%;
}

.card-produto .img-produto {
    width: 100px !important;
}


.detalhes-produto .descricao {
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 600;
    margin-top: 0.5rem;
    color: var(--cor-preto);
    text-decoration: underline;
}

.detalhes-produto .preco {
    font-size: 1rem;
    line-height: 1.875rem;
    font-weight: bold;
    color: var(--cor-primaria) !important;
}

.detalhes-produto .preco-promocao {
    font-size: .8rem !important;
    color: var(--cor-cinza) !important;
    text-decoration: line-through;
}