.detalhesProduto img {
    width: 200px;
    margin: auto;
}

.detalhesProduto .card-text {
    color: var(--cor-cinza);
    font-weight: bold;
}

.detalhesProduto .card-body p {
    font-size: 12px;
}

.detalhesProduto .qtd-disponivel {
    margin-top: 3px;
    font-size: 12px;
}

.detalhesProduto .fabricante {
    color: var(--cor-secundaria);
}

.detalhesProduto .texto-descricao p {
    color: var(--cor-preto);
    font-size: 13px;
}

.detalhesProduto .card {
    position: relative;
}

.detalhesProduto .preco {
    font-size: 24px !important;
    font-weight: bolder;
}