.breadcrumb {
    width: 100%;
    display: flex;
    justify-content: center;
}

.breadcrumb .titulo-breadcrumb {
    font-size: 11px;
    text-align: center;
}

.breadcrumb-item+.breadcrumb-item::before {
    float: initial;
    padding-right: initial;
    /* color: initial; */
    content: "";
}

.breadcrumb li {
    margin: 0;
    padding: 0;
}

.breadcrumb li a, .breadcrumb li a:hover  {
    padding: 10px !important;
    border: 3px solid #a3a3a3;
    color: #a3a3a3 !important;
    border-radius: 50%;
}

.breadcrumb li.active a, .breadcrumb li.active a:hover {
    border: 3px solid var(--cor-primaria);
    color: var(--cor-primaria) !important;
}

.breadcrumb .linha-horizontal {
    width: 25%;
    background: #a3a3a3;
    margin: 0;
    padding: 0;
    height: 4px;
    margin-top: 10px;
}