html {
    overflow-x: hidden;
    overflow-y: unset;
}

body {
    overflow: hidden;
    background-color: var(--cor-fundo) !important;
}

p {
    font-size: 14px;
    margin: 0px !important;
}

/* Estilo para inputs em form-groups */
input.form-control {
    border: 1px solid var(--cor-cinza-suave);
    border-radius: 5px;
    box-shadow: none;
    padding: 10px 0;
    font-size: 14px !important;
    font-style: normal;
    background-color: var(--cor-fundo) !important;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    box-sizing: border-box;
}

.form-control.is-invalid{
    border-color: var(--cor-vermelho);
}

label {
    font-size: 13px;
    color: var(--cor-cinza-suave);
    transition: color 0.3s;
    background-color: transparent !important;
}

label::after {
    background-color: var(--cor-fundo) !important;
}

/* Estilo para inputs em form-groups quando estão em foco */
input.form-control:focus {
    outline: none;
    border-color: var(--cor-secundaria);
    box-shadow: none;
}

/* Estilo para labels em form-groups quando o input tem foco ou está preenchido */
input.form-control:focus~label,
input.form-control:valid~label {
    color: var(--cor-primaria);
}

/* Estilo para mensagens de erro em form-groups */
.error-message {
    color: var(--cor-vermelha);
    font-size: 14px;
    display: none;
}

/* Estilo para mensagens de erro em form-groups quando há um erro */
.error-message.show {
    display: block;
}



/* MEUS ESTILOS */

.btn {
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    color: var(--cor-fundo);
}

.btn.btn-primario {
    background-color: var(--cor-primaria);
    border: none;
    color: #fff;
}

.btn.btn-primario:disabled {
    opacity: .75;
    background-color: var(--cor-primaria);
    border: none;
    color: #fff;
}

.btn.btn-primario:hover,
.btn.btn-primario:active {
    background-color: var(--cor-primaria-2) !important;
    border: none !important;
    color: #fff !important;
}

.btn.btn-secundario {
    border: none;
    background-color: var(--cor-secundaria);
    color: #fff;
}

.btn.btn-secundario:disabled {
    opacity: .75;
    border: none;
    background-color: var(--cor-secundaria);
    color: #fff;
}

.btn.btn-secundario:hover,
.btn.btn-secundario:active {
    color: #fff !important;
    background-color: var(--cor-secundaria-2) !important;
}

.btn.btn-sem-estilo {
    border: none;
    color: #fff;
}

.btn.btn-sem-estilo:hover,
.btn.btn-sem-estilo:active {
    text-decoration: underline !important;
    color: #fff !important;
}

.btn-facebook {
    border: 1px solid rgb(0, 96, 177) !important;
    color: rgb(0, 96, 177) !important;
    font-weight: bold !important;
}

.btn-facebook:hover {
    background-color: rgb(0, 96, 177) !important;
    color: #fff !important;
    font-weight: bold !important;
}

.btn-google {
    border: 1px solid rgb(231, 38, 38) !important;
    color: rgb(231, 38, 38) !important;
    font-weight: bold !important;
}

.btn-google:hover {
    background-color: rgb(231, 38, 38) !important;
    color: #fff !important;
    font-weight: bold !important;
}

.link {
    color: var(--cor-secundaria);
    font-weight: bold;
}

.required {
    color: var(--cor-vermelho);
    margin-left: 4px;
}



@keyframes pulse {
    0% {
        opacity: 0.80;
    }

    25% {
        opacity: 0.90;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0.80;
    }

    100% {
        opacity: 0.90;
    }
}


.selo-promocao span {
    color: #fff;
    background-color: var(--cor-secundaria);
    padding: 3px 15px 3px 15px;
    font-size: 12px;
    border-radius: 3px;
    animation: pulse 1s infinite;
}

.preco {
    color: var(--cor-primaria) !important;
    font-size: 14px;
    font-weight: bold;
}

.preco-desconto {
    font-weight: normal;
    font-size: 12px;
    color: var(--cor-cinza) !important;
    text-decoration: line-through !important;
    opacity: .9;
}


.lbl-quantidade input {
    width: 60px;
    height: 32px;
    border-radius: 20px;
    text-align: center;
}

.div-quantidade {
    position: relative;
}

.img-btn {
    width: 25px !important;
    height: 25px !important;
}



/* card customizado */
.card-customizado .card-header h5 {
    font-size: 14px;
    color: var(--cor-preto);
    font-weight: bolder;
    margin: 0;
    padding: 0;
}

.card-customizado .card-body strong {
    font-size: 13px !important;
    color: var(--cor-preto);
}

.card-customizado .card-body span {
    font-size: 12.5px !important;
    font-weight: 500 !important;
    color: var(--cor-preto)
}

.card-customizado .card-header a {
    font-size: 13px;
}



/* cores de texto */
.texto-vermelho {
    color: var(--cor-vermelho) !important;
}

.texto-primario {
    color: var(--cor-primaria) !important;
}

.texto-verde {
    color: var(--cor-verde) !important;
}

.bg-primario {
    background-color: var(--cor-primaria) !important;
}


/* Cesta e finalizar pedidos */
.info-totalPagamento {
    background-color: var(--cor-primaria);
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1000;
    color: #fff;
    left: 12px;
}

.info-totalPagamento .total-pagar {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: .01px;
}

.card-opcao-select {
    font-size: 14px;
    padding: 20px 15px 20px 15px;
    border-radius: 8px;
}

.card-opcao-select .selecionado {
    background-color: #dedede !important;
}