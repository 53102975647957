.qtd-itens-cesta {
    font-size: .8rem;
    color: var(--cor-preto);
    font-weight: 600;
}

.descricao-item {
    font-size: 13px;
    word-wrap: break-word;
}

.card-item-cesta {
    border: 1px solid var(--cinza-claro) !important;
}

.card-item-cesta .form-control {
    height: 12px !important;
    font-size: 10px !important;
}

.btn-lixeira-cesta {
    color: var(--cor-vermelho);
    /* border: .01mm solid var(--cor-preto) !important; */
}

.btn-lixeira-cesta:active {
    color: var(--cor-secundaria-2);
    /* border: 2px solid var(--cinza-claro) !important; */
}

.btn-lixeira-cesta:focus {
    /* border: 2px solid var(--cinza-claro) ; */
    color: var(--cor-vermelho);
}

.card-item-cesta .btn-lixeira-cesta {
    position: absolute;
    z-index: 1000;
    right: 4px;
    top: 4px;
}


.cesta .card-customizado .card-body div strong,  .cesta .card-customizado .card-body span{
    font-size: 14px !important;
}

.container-cesta {
    position: relative;
}
